import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Projects = () => {
  const projects = [
    {
      title: "Omnifood",
      description: "HTML, CSS, JavaScript",
      imgUrl: projImg1,
      link: "https://omnifood-shumin.netlify.app",
    },
    {
      title: "WorldWise",
      description: "JavaScript, React",
      imgUrl: projImg2,
    },
    {
      title: "usePopcorn",
      description: "JavaScript, React",
      imgUrl: projImg3,
      link: "https://usepopcorn-shumin.netlify.app/",
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <h2>Projects</h2>

            <Tab.Container id="projects-tabs" defaultActiveKey="first">
              <Tab.Content id="slideInUp">
                <Tab.Pane eventKey="first">
                  <Row>
                    {projects.map((project, index) => {
                      return <ProjectCard key={index} {...project} />;
                    })}
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
        <div className="project-note">
          <h3>
            ⚠️ Still working on this part. More projects will be displayed here
            soon! 😉
          </h3>
          <h4>Updates from Aug 31, 2023 👆🏻</h4>
        </div>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
      <img className="background-image-left" src={colorSharp2}></img>
    </section>
  );
};
