import { Container, Row, Col } from "react-bootstrap";
import Logo from "./Logo";
import navLinkedin from "../assets/img/nav-linkedin.svg";
import navGithub from "../assets/img/nav-github.svg";
import navIns from "../assets/img/nav-ins.svg";
import logo from "../assets/img/logo-removebg.png";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={6}>
            <Logo>
              <img src={logo} alt="Logo" />
            </Logo>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/shumin-zhang/">
                <img src={navLinkedin} alt="Icon" />
              </a>
              <a href="https://github.com/shuminCA">
                <img src={navGithub} alt="Icon" />
              </a>
              <a href="https://www.instagram.com/shuminzhang1207/?hl=en">
                <img src={navIns} alt="Icon" />
              </a>
            </div>
            <p>
              &copy; Copyright {new Date().getFullYear()}. All Rights Reserved
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
