import { useState, useEffect, Suspense } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/catgirl2.png";
import { ArrowRightCircle } from "react-bootstrap-icons";
import "animate.css";
import Logo from "./Logo";
import logoS from "../assets/img/logo1.png";

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Hi, I'm Shumin"];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex((prevIndex) => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <section className="banner" id="home">
      <Container>
        <Row>
          <Col xs={12} md={6} xl={7} className="banner-text">
            <div className="flex-column">
              <span className="tagline">Welcome to my Portfolio</span>
              <h1 className="txt-rotate" dataPeriod="1000">
                <span className="wrap">{text}</span>
              </h1>
              {/*
               */}
              <p>
                I am a <strong>Full-Stack Developer</strong> working remotely
                from
                <strong> San Francisco Bay Area</strong>.
              </p>
              <p>Reach out if you'd like to learn more.</p>
              <a href="#connect">
                <button onClick={() => console.log("connect")}>
                  Let's Connect <ArrowRightCircle size={25} />
                </button>
              </a>
            </div>
          </Col>
          <Col xs={12} md={6} xl={5} className="banner-img">
            <div className="move">
              <img src={headerImg} alt="Header Img" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
