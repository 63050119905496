import React from "react";

import styles from "./About.module.css";
import studentImg from "../../assets/img/about/cap-removebg-preview.png";
import developerImg from "../../assets/img/about/computer3-removebg-preview.png";
import reporterImg from "../../assets/img/about/giftbox3-removebg-preview.png";
import girlImg from "../../assets/img/catgirl.png";

export const About = () => {
  return (
    <section className="about" id="about">
      <h2 className={styles.title}>About</h2>
      <div className={`${styles.content} ${styles.aboutContainer}`}>
        <img
          src={girlImg}
          alt="Me sitting with a laptop"
          className={styles.aboutImage}
        />
        <ul className={styles.aboutItems}>
          <li className={styles.aboutItem}>
            <img src={studentImg} alt="student icon" />
            <div className={styles.aboutItemText}>
              <h3>Second-Year CS Master</h3>
              <p>
                I'm a second-year CS master's student at the University of San
                Francisco.
              </p>
            </div>
          </li>
          <li className={styles.aboutItem}>
            <img src={developerImg} alt="Server icon" />
            <div className={styles.aboutItemText}>
              <h3>Full-Stack Developer</h3>
              <p>
                I'a a full-stack developer with experience in building
                responsive sites and developing fast and optimized back-end
                systems and APIs.
              </p>
            </div>
          </li>
          <li className={styles.aboutItem}>
            <img src={reporterImg} alt="UI icon" />
            <div className={styles.aboutItemText}>
              <h3>Other</h3>
              <p>
                Over the past 7+ years, I've worked in the technology, media,
                and telecom (TMT) sector, including full-stack development, data
                analysis and journalism.
              </p>
              {/* <p>I'm proud to have worn many hats.</p> */}
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};
