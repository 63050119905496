import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import "animate.css";
import TrackVisibility from "react-on-screen";
import { useForm, ValidationError } from "@formspree/react";
import Map from "./Map";

export const Contact = () => {
  const formInitialDetails = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState("Send");
  const [status, setStatus] = useState({});
  const [state, handleSubmit] = useForm("mpzgdvgv");

  // if (state.succeeded) {
  //   return <p>Thanks for your submission!</p>;
  // }

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  return (
    <section className="contact" id="connect">
      <h2>Get In Touch</h2>
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <form onSubmit={handleSubmit} method="POST">
              <Row>
                <Col size={12} sm={6} className="px-1">
                  <input
                    type="text"
                    name="firstName"
                    value={formDetails.firstName}
                    placeholder="First Name"
                    onChange={(e) => onFormUpdate("firstName", e.target.value)}
                    required
                  />
                  <ValidationError
                    prefix="Name"
                    field="name"
                    errors={state.errors}
                  />
                </Col>
                <Col size={12} sm={6} className="px-1">
                  <input
                    type="text"
                    name="lastName"
                    value={formDetails.lasttName}
                    placeholder="Last Name"
                    onChange={(e) => onFormUpdate("lastName", e.target.value)}
                    required
                  />
                  <ValidationError
                    prefix="LastName"
                    field="lastName"
                    errors={state.errors}
                  />
                </Col>
                <Col size={12} sm={6} className="px-1">
                  <input
                    type="email"
                    name="email"
                    value={formDetails.email}
                    placeholder="Email Address"
                    onChange={(e) => onFormUpdate("email", e.target.value)}
                    required
                  />
                  <ValidationError
                    prefix="FirstName"
                    field="firstName"
                    errors={state.errors}
                  />
                </Col>
                <Col size={12} sm={6} className="px-1">
                  <input
                    type="tel"
                    name="tel"
                    value={formDetails.phone}
                    placeholder="Phone No."
                    onChange={(e) => onFormUpdate("phone", e.target.value)}
                  />
                  <ValidationError
                    prefix="Tel"
                    field="tel"
                    errors={state.errors}
                  />
                </Col>
                <Col size={12} className="px-1">
                  <textarea
                    rows="6"
                    value={formDetails.message}
                    name="message"
                    placeholder="Message"
                    onChange={(e) => onFormUpdate("message", e.target.value)}
                  ></textarea>
                  <ValidationError
                    prefix="Message"
                    field="message"
                    errors={state.errors}
                  />
                  {state.succeeded ? (
                    <h3>
                      Thank you for you submission! I will reach out to you
                      soon. 😉
                    </h3>
                  ) : (
                    <button type="submit" disabled={state.submitting}>
                      <span>{buttonText}</span>
                    </button>
                  )}
                </Col>
                {status.message && (
                  <Col>
                    <p
                      className={
                        status.success === false ? "danger" : "success"
                      }
                    >
                      {status.message}
                    </p>
                  </Col>
                )}
              </Row>
              <ValidationError errors={state.errors} />
            </form>
          </Col>
          <Col size={12} md={6} className="map">
            <Map />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
