import { Navbar } from "react-bootstrap";

function Logo({ children }) {
  return (
    <Navbar.Brand href="/" className="logoContainer">
      {children}
    </Navbar.Brand>
  );
}

export default Logo;
